
<script setup>
    import { computed, provide } from "vue";

    import MContainer from "mobile.vue.components.Container.vue";
    import MContent from "mobile.vue.components.Content.vue";

    const props = defineProps({
        modelValue: { type: String },
    });
    const emit = defineEmits(["update:modelValue"]);

    const model = computed({
        get() { return props.modelValue },
        set(val) { emit("update:modelValue", val) },
    });

    provide("tab", model);
</script>

<template>
    <MContent>
        <slot />
    </MContent>
</template>
